@import '~rsuite/styles/index.less';
@import '../components/Frame/styles.less';
@import '../components/Logo/styles.less';
@import '../components/Header/styles.less';

//rewrite base color
@base-color: #34c3ff;

body {
  background: #f5f8fa;
}

.text-muted {
  color: @B700;
}

.rs-sidebar {
  position: fixed;
  height: 100vh;
  z-index: 3;
}

.page-container {
  padding-left: 200px;
  transition: padding 0.5s;
  &.container-full {
    padding-left: 60px;
  }
}

.bg-gradient-orange {
  background: linear-gradient(87deg, #fb6340 0, #fbb140 100%);
}

.bg-gradient-red {
  background: linear-gradient(87deg, #f5365c 0, #f56036 100%);
}

.bg-gradient-green {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%);
}

.bg-gradient-blue {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
}

body {
  margin: 0;
  font-family: 'Encode Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

h5 {
  font-size: 14px;
  line-height: 1.5;
}
