.frame {
  height: 100vh;

  .rs-sidebar {
    background: #1c1e31;
    box-shadow: #00000080 -5px 0 5px inset;
  }

  .rs-sidenav-default,
  .rs-navbar-default,
  .rs-sidenav-item {
    background: transparent;
    color: @B200;
    text-decoration: none;
  }

  .rs-sidenav-item:hover,
  .rs-navbar-item:hover,
  .rs-navbar-item:focus {
    background: #242734;
    color: @B200;
  }

  .rs-sidenav {
    flex: 1 1 auto;
    transition: none !important;
    border-top: 1px solid #ffffff30;
    width: 100%;
  }

  .rs-content {
    padding: 0 10px;

    .rs-panel-header {
      .title {
        font-size: 18px;
      }
      .rs-breadcrumb {
        margin-bottom: 0;
      }
    }
  }
  .nav-toggle {
    border-top: 1px solid #ffffff30;
  }

  .rs-sidenav-item,
  .rs-dropdown-item {
    &.active {
      color: @H500 !important;
    }
    &-icon {
      height: 1.3em !important;
      width: 1.3em !important;
    }
  }
}
