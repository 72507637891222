.brand {
  padding: 10px 18px;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
  color: #fff;
  text-shadow: rgba(255, 255, 255, 0.65) 0 0 5px;

  span:nth-of-type(1) {
    line-height: 100%;
  }

  span:nth-of-type(2) {
    font-size: 8px;
  }

  a {
    text-decoration: none;
    color: #fff;
  }
}

.brand {
  a:hover, a:active {
    color: #fff;
    text-decoration: none;
  }
}
